@import "../../style.css";

.padingStart {
  padding-left: 20px;
}

.mainBg {
  background-color: var(--light-odds);
  font-family: var(--font-poppins);
}

.responsiveHeader {
  height: 64px;
  background-color: var(--dark-blue);
  color: var(--white);
  margin-right: -12px;
  padding: 20px;
  display: none;
}

.menuIconFontRH {
  font-size: var(--font-size-5xl);
}

.secrchIconRH {
  margin-right: 20px;
}

.logoRH {
  font-size: 18px;
  font-weight: bold;
  margin-left: 8px;
}

.openMenu {
  position: absolute;
  z-index: 11;
}

@media screen and (max-width: 768px) {
  .mainBg {
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .noScroll {
    overflow-y: hidden;
  }

  .sidebar {
    display: none;
  }

  .padingStart {
    padding-left: 10px !important;
  }

  .responsiveHeader {
    display: block !important;
  }
}
