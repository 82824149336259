@import "../../style.css";

.headerBg {
  background-color: var(--white);
  padding: 25px 25px 25px 18px;
}

.dropDownItem {
  height: 250px;
  overflow-y: scroll;
}

.mainHeader {
  display: flex;
  align-items: center;
  gap: 35px;
  display: flex;
  justify-content: end;
}

.oddsFormate {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 7px; */
}

.oddsFormate span {
  font-size: var(--font-size-xsm);
  display: contents;
}

.oddsFormate div span {
  color: var(--light-purple);
}

.btnRegister {
  border-radius: var(--br-large);
  border: none;
  background-color: var(--light-purple);
  padding: 10px 28px;
  color: var(--white);
}

.btnRegister:hover {
  background-color: #448efc;
  cursor: pointer;
}

.activeButton {
  border-radius: var(--br-large);
  border: none;
  background-color: var(--light-purple);
  padding: 10px 28px;
  color: var(--white);
}

.activeButton:hover {
  background-color: #448efc;
  cursor: pointer;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.active {
  border-radius: var(--br-large);
  border: none;
  background-color: var(--light-purple);
  padding: 10px 28px;
  color: var(--white);
}

.btnLogin {
  border-radius: var(--br-large);
  border: 1px solid var(--light-purple);
  background-color: transparent;
  padding: 10px 28px;
  color: var(--light-purple);
}

.btnLogin:hover {
  background-color: #448efc;
  color: var(--white);
  cursor: pointer;
}

.header {
  padding: 28px 0px 0px;
}

.menubar nav ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.8vw;
  margin-top: 20px;
}

.menubar nav ul li {
  font-size: var(--font-size-xsm);
}

.giveGap {
  margin-left: 60px;
}

.searchTeam {
  padding: 14px 30px;
  background-color: var(--light-purple-bg-light);
  border-radius: var(--br-lsm);
  text-align: center;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchTeam input {
  border: none;
  outline: none;
  background-color: transparent;
  max-width: 300px;
}

.userName {
  display: flex;
  align-items: center;
  gap: 12px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .headerBg {
    padding: 16px 24px;
  }

  .mainHeader {
    display: block !important;
  }

  .oddsFormate {
    gap: 3px !important;
  }

  .oddsFormate span {
    font-size: var(--font-size-xs) !important;
  }

  .oddsFormate div {
    font-size: var(--font-size-xs) !important;
  }

  .timeZone {
    display: block !important;
  }

  .btnRegister {
    padding: 6px 50px !important;
  }

  .btnLogin {
    padding: 5px 50px;
  }

  .mt {
    margin-top: 16px;
  }

  .header {
    display: none !important;
  }

  .btnPadding {
    padding: 10px 24px !important;
  }

  .hideHeader {
    display: none !important;
  }
}