@import "../../style.css";

.bookMakersBg {
  background-color: var(--white);
  padding: 25px 25px;
  border-radius: var(--br-sm);
}
.cancledMatch {
  text-decoration: line-through !important;
}
.lightBg {
  background-color: var(--light-purple-bg-light) !important;
}
.nameNavbar {
  color: var(--light-purple);
}
.nameNavbar span {
  font-size: var(--font-size-sm);
}
.countryName {
  color: var(--color-darkslateblue-900);
  font-weight: 600;
  text-transform: capitalize;
}
.largeLabel {
  font-size: var(--font-size-5xl);
  font-weight: 500;
}
.numberMultiply {
  font-size: var(--font-size-sm);
  font-weight: 500;
  border-bottom: 2px solid var(--light-purple);
}
.headerOfTeam {
  font-size: var(--font-size-sm);
  font-weight: 500;
  color: var(--gray);
}
.sectionOfTime {
  border-radius: var(--br-large);
  border: none;
  background-color: var(--light-purple-bg);
  padding: 10px 28px;
  color: var(--light-purple);
}
.sectionOfTimeBg {
  background-color: var(--light-purple);
  color: var(--white);
}
.tableBookmakers {
  border-radius: var(--br-sm) !important;
}

.showBorder thead,
.showBorder tbody,
.showBorder tr,
.showBorder td {
  border: 1px solid #656ef530 !important;
  border-bottom: 1px solid #656ef530 !important;
  /* border-top: 1px solid #656ef530 !important; */
  border-right: 1px solid #656ef530 !important;
  /* border-left: 1px solid #656ef530 !important; */
}

/*  */

.tableOutLine {
  outline: 1px solid var(--light-purple) !important;
  border-radius: 9px;
  border: 1px solid #656ef530;
  /* overflow: hidden; */
}
.tableOutLine .table {
  /* border: none; */
  border-radius: 16px;
}

.tableOutLine table,
.tableOutLine tr,
.tableOutLine td {
  border: 1px solid #656ef530 !important;
  border-bottom: none !important;
  /* border-top: none !important; */
  border-right: none !important;
  /* border-left: none !important; */
}
.tableOutLine table {
  margin-bottom: 0;
}

.tableHeading {
  width: 75%;
  height: 50px;
  font-size: var(--font-size-xsm);
}
.profitLossAmount {
  width: 8%;
  text-align: center;
  font-size: var(--font-size-xsm);
}

.movementScroll {
  height: 400px;
  overflow-y: scroll;
}
.movementScrollFitContent {
  width: fit-content;
}
.movementScroll::-webkit-scrollbar {
  display: none;
}

.plusAmount {
  background-color: #def3dd;
  width: -moz-fit-content;
  width: 100%;
  height: 28px;
  border-radius: 8px;
  padding: 2px;
  margin-top: 6px !important;
  font-size: var(--font-size-xsm);
  color: #26b01a;
  border: none;
}
.plusAmount::after {
  display: none;
}

.minusAmount {
  background-color: #ffd9d9;
  width: 100%;
  height: 28px;
  /* border-radius: 8px; */
  padding: 4px;
  font-size: var(--font-size-xsm);
  color: #ff0000;
  border: none;
}
.minusAmount::after {
  display: none;
}
.numbersOfBet {
  font-size: var(--font-size-sm);
}
.percentageNumber {
  font-size: var(--font-size-xsm);
  padding: 4px;
}
.oddsMovementsBg {
  /* border-radius: var(--br-medium); */
  /* border-color: var(--light-purple); */
  border: 1px solid var(--light-purple);
  /* height: 500px; */
  /* width: 246px; */
  width: auto;
  padding: 10px !important;
}

.title {
  color: var(--light-purple);
  font-size: var(--font-size-base);
  text-align: center;
}
.timeAndDate {
  font-size: var(--font-size-xsm);
  margin-top: 12px;
}
.profit {
  font-weight: 600;
}
.loss {
  font-weight: 600;
  color: var(--red) !important;
}
.down {
  font-weight: 600;
  color: var(--red) !important;
  margin-inline-start: auto;
}
.up {
  font-weight: 600;
  color: var(--green) !important;
  margin-inline-start: auto;
}
.joiningTimeOdds {
  font-size: var(--font-size-xsm);
  color: var(--light-purple);
  margin-top: 15px;
}

/* PremierLeague */

.premierLeagueBg {
  border-radius: var(--br-sm);
  background-color: var(--white);
  padding: 16px 20px;
}

.premierLeagueBg hr {
  color: var(--light-purple);
}
.premierLeagueBg label {
  font-size: var(--font-size-sm);
  font-weight: 500;
}
/* .scheduleOfPremierLeague {
  display: flex;
  align-items: center;
  
  gap: 20px;
} */
.displayTime {
  font-size: var(--font-size-xsm);
}
.teamName {
  font-size: var(--font-size-xsm);
  font-weight: 500;
}
.setLine {
  opacity: 1;
  color: var(--light-purple);
  width: 2px;
  /* height: 2px; */
}
.avgAndMaxValue td {
  font-size: var(--font-size-base);
}
.betLogo {
  width: 14%;
  height: inherit;
}
.addCouponSave :hover {
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .bookMakersBg {
    padding: 15px 5px;
  }
  .navbarDisplay {
    display: none !important;
  }
  .countryAndTeamName {
    display: block;
  }
  .largeLabel {
    font-size: var(--font-size-base) !important;
  }
  .matchTypes {
    gap: 20px !important;
  }
  .numberMultiply {
    font-size: var(--font-size-sm-3);
  }
  .headerOfTeam {
    font-size: var(--font-size-sm-3);
  }
  .sectionOfTime {
    padding: 10px 24px !important;
    font-size: var(--font-size-smi) !important;
  }
  .tableHeading {
    /* width: 25% !important; */
    height: 20px !important;
    font-size: var(--font-size-xs);
  }
  .title {
    font-size: var(--font-size-sm);
  }
  .timeAndDate {
    font-size: var(--font-size-xs);
  }
  .joiningTimeOdds {
    font-size: var(--font-size-xs);
  }
  .profit {
    font-size: var(--font-size-xs);
  }
  .avgAndMaxValue td {
    font-size: var(--font-size-sm);
  }

  .betLogo {
    height: 20px;
    width: 50px;
  }
  .profitLossAmount {
    font-size: var(--font-size-3xs);
  }
  .numbersOfBet {
    font-size: var(--font-size-3xs);
  }
  .ms {
    margin-left: 0px !important;
    font-size: 18px;
  }

  .plusAmount {
    width: 45px;
    height: 20px;
    padding: 4px;
    font-size: var(--font-size-3xs);
  }
  .minusAmount {
    width: 45px;
    height: 20px;
    padding: 4px;
    font-size: var(--font-size-3xs);
  }
  .percentageNumber {
    font-size: var(--font-size-3xs);
    padding: 4px;
  }
  .flexReverse {
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: left;
  }
}
