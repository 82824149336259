@import "../../style.css";

.custom_stack_wrap {
  flex-wrap: wrap;
  /* Enables wrapping for the stack items */
}

.myCouponIcon {
  font-size: var(--font-size-base);
  color: var(--light-purple);
}

.bannerImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.iconFont {
  font-size: var(--font-size-sm);
}

.myCouponBg {
  border-radius: var(--br-sm);
  background-color: var(--white);
  padding: 16px 20px;
}

.myCouponBg hr {
  color: var(--light-purple);
}

.myCouponTitle {
  color: var(--dark-blue);
  font-size: var(--font-size-sm);
  font-weight: 500;
  /* margin-left: 10px; */
}

.MyCouponCount {
  border-radius: 100%;
  color: var(--light-purple);
  padding: 2px 7px;
  background-color: var(--light-purple-bg);
  height: 20px;
  width: 20px;
  font-size: var(--font-size-xs);
  font-weight: 600;
}

.myCouponDescription {
  font-size: var(--font-size-smi);
}

.savedCoupon {
  background-color: var(--light-purple-bg-light);
  color: var(--light-purple);
  border-radius: var(--br-medium);
  font-size: var(--font-size-xsm);
}

.savedCoupon:hover {
  cursor: pointer;
}

.savedCouponBg {
  background-color: var(--light-purple-bg-light);
}

.savedCouponWidth {
  width: fit-content;
}

.bettingItem {
  font-size: var(--font-size-sm);
}

.bettingItemIcon {
  font-size: var(--font-size-base);
  color: var(--light-purple);
}

.correct {
  background-color: var(--light-purple);
  color: var(--white);
  font-size: var(--font-size-smi);
  width: 20px;
  height: 20px;
  padding: 3px 4px;
  border-radius: 5px;
}

.playerName {
  font-size: var(--font-size-xsm);
}

.topEvent {
  border-bottom: 2px solid var(--light-purple);
  width: fit-content;
  padding: 5px 10px;
  white-space: nowrap;
}

.topEventBdr {
  border-left: 1px solid var(--pink);
}

.SetPlace {
  padding: 16px 18px !important;
}

.sportsList {
  border-radius: 16px 16px 00 00;
}

.sportsName {
  background-color: var(--white);
  border-radius: 00 00 16px 16px;
}

.bgTopEvents {
  background-color: #fef7ff;
  padding: 16px 30px;
  margin-left: -11px;
  margin-right: -12px;
}

.gap10 {
  display: flex;
  align-items: center;
  gap: var(--gap);
}

.matchResult {
  width: fit-content;
  height: 33px;
  padding: 8px 16px !important;
  border-radius: var(--br-medium);
  color: "#000";
  font-size: var(--font-size-smi);
  font-weight: 600;
}

.finalResult {
  font-size: var(--font-size-xsm);
  font-weight: 500;
}

.otMatch {
  background-color: var(--red);
  color: var(--white);
}

.wonMatch {
  background-color: var(--green);
  color: var(--white);
}

.wonTitle {
  font-size: var(--font-size-smi);
}

.bestOdds {
  border: 1px solid var(--light-purple);
  border-radius: 10px;
  padding: 20px 20px;
  /* margin: 00 30px; */
}

.bestOddsTitle {
  font-size: var(--font-size-xsm);
  font-weight: 500;
}

.bestOddsItem {
  height: 24px;
  width: 72px;
  border-radius: var(--br-medium);
}

.bestOdds p {
  font-size: 16px;
  margin-bottom: 0px;
}

.bestOddsItemIcon {
  font-size: 18px;
}

.logoOfOdds {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
}

.compare {
  text-decoration: underline;
  color: var(--light-purple);
  font-size: var(--font-size-xsm) !important;
}

.myCheckbox {
  height: 15px;
  width: 15px;
}

.checkEmail {
  font-size: var(--font-size-xsm);
  font-weight: 500;
}

.bestOddsCancle {
  color: var(--red);
  margin-bottom: 0px;
  font-size: var(--font-size-xsm);
  font-weight: 500;
}

.navTab {
  display: flex;
  align-items: center;
  color: var(--dark-blue);
  font-size: var(--font-size-sm);
  padding: 8px !important;
}

.navTab :hover {
  cursor: pointer !important;
}

.navTabFont {
  font-size: var(--font-size-xsm);
}

.more {
  color: var(--light-purple);
}

.activeButton {
  border-radius: var(--br-large);
  border: none;
  background-color: var(--light-purple);
  padding: 10px 28px;
  color: var(--white);
}

.activeButton:hover {
  background-color: #448efc;
  cursor: pointer;
}

.leaguesHeading {
  background-color: var(--light-purple-bg-light);
  padding: 10px;
  margin-left: -11px;
  margin-right: -12px;
  font-weight: bold;
}

.leaguesHeading p {
  font-size: 16px;
  line-height: 1.5;
}

.couponResult {
  color: rgb(101, 110, 245);
  text-align: end;
  width: 200px;
  margin-left: auto;
}

.activeSportName {
  background: #f0f1fe;
  padding: 00 10px;
  border-radius: 25px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .evetTabDisplay {
    display: block;
  }

  .myCouponDisplay {
    display: none !important;
  }

  .topEventBdr {
    border: none;
    padding-left: 11px;
    padding-top: 0px !important;
  }

  .iconFont {
    font-size: var(--font-size-sm) !important;
  }

  .navTabFont {
    font-size: var(--font-size-sm-3);
  }

  .myCouponTitle {
    font-size: var(--font-size-xsm);
  }

  .topEvent {
    padding: 6px;
  }

  .gap {
    gap: 25px !important;
  }

  .bettingItemIcon {
    font-size: var(--font-size-sm);
  }

  .savedCoupon {
    font-size: 11px;
  }

  .contentBetween {
    gap: 5px;
  }
}

.couponTitle:hover {
  color: #448efc;
  cursor: pointer;
}

.banner_Img img {
  width: 100%;
  height: 100%;
}