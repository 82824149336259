@import "../../style.css";

.premierTitle {
  font-size: var(--font-size-5xl);
  display: flex;
  align-items: center;
  margin-top: 32px;
}
.cancledDisplay {
  display: none;
}
.standingsResult {
  background: #b9b9b9;
  border-radius: 5px;
  width: 20%;
  text-align: center;
  color: white;
  font-weight: 700;
}
.standingsResultW {
  background: #23bf24;
}
.standingsResultL {
  background: #dc2427 !important;
}
.standingsResultD {
  background: #f5ab39 !important;
}
.bg {
  background-color: var(--white);
  padding: 10px;
  margin-top: 12px;
}

.dataItem {
  width: 100%;
  margin: auto !important;
  align-items: center;
}
.dataItemBorder {
  border: 1px solid var(--light-purple);
  border-radius: 8px;
  margin-top: 10px;
}
.b {
  border-bottom: 1px solid var(--light-purple) !important;
}

.countryName {
  font-size: var(--font-size-xsm);
  font-weight: 500;
  text-transform: capitalize;
  align-items: center;
}

.bookmakersStatus {
  background-color: #23bf24;
  color: white;
  padding: 5px;
  width: fit-content;
}
.marginLeft15 {
  margin-left: 15px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .premierTitle {
    font-size: var(--font-size-base);
    width: 60%;
  }
  .matchPoint {
    width: 100% !important;
  }
  .cancledDisplay {
    display: block !important;
  }
  .contentBetween {
  }
  .setDisplay {
    display: block;
  }
  .pointBox {
    justify-content: space-between !important;
  }
  .pointBox1 {
    /* gap: 20px; */
    justify-content: space-around !important;
  }
  .displayReverse {
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
    margin-bottom: 6px;
  }
  .displayReverseFlexEnd {
    justify-content: flex-end !important;
  }
  .premierLanDisplay {
    display: none;
  }
  .premierLanMargin {
    margin: 3px !important;
  }
  .btnMargin {
    margin-top: 10px;
  }
  .marginLeft15 {
    margin-left: 0px !important;
  }
  .textDecoration {
    max-width: 110px !important;
    text-overflow: ellipsis !important;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
  }
  .marginLeft0 {
    margin-left: 0px !important;
  }
  .matchPointMargin {
    margin-top: 10px !important;
  }
  .headerPart {
    margin-left: 14px;
  }
  .headerPart1 {
    margin-left: 10px;
  }
  .countryName {
    font-size: var(--font-size-smi);
    text-transform: capitalize;
  }
  .smFlag {
    margin-left: 0px !important;
  }
  .msSet {
    margin-left: 0px !important;
  }
}
