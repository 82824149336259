@import "../../style.css";

.sideBg {
  background-color: var(--color-darkslateblue-900);
  height: 100vh;

  padding: 0px 10px 0px 8px;
  color: var(--light-odds);
  position: fixed;
  width: 16.7%;
  font-family: Poppins;
  overflow-y: scroll;
}

.sideBg::-webkit-scrollbar {
  display: none;
}

.sideBgRH {
  width: 100% !important;
}

.logo {
  padding-top: 16px;
  text-align: center;
}

.logo h4 {
  font-size: 24px;
  font-weight: bold;
}

.decorationLine {
  border: 1px solid var(--light-odds);
  margin-top: 22px;
}

.languageCard {
  margin-top: 18px;
  height: 110px;
  border: none;
  border-radius: 16px;
  background-color: var(--color-mediumslateblue-200);
  padding: 15px 16px;
}

.selectLanguage {
  display: flex;
  align-items: center;
  gap: 14px;
}

.countLanguage {
  border-radius: 100%;
  background-color: var(--light-odds);
  height: 20px;
  width: 20px;
  color: var(--color-darkslateblue-900);
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.manageLanguage {
  margin-bottom: 0px;
}

.sportsMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sportsAndCountryBg {
  padding: 5px;
  margin: 10px;
  margin-left: 12px;
  padding-top: 20px;
  background-color: white;
  border-radius: 16px;
}

.accordion .item .accordion-header button {
  box-shadow: none;
}

.accordionItem {
  border: none !important;
  background-color: transparent;
}

.accordionItem button {
  background-color: #121432 !important;
  color: white;
  box-shadow: none !important;
}

.accordionItem button:not(.collapsed) {
  color: white;
}

.accordionItem :not(.collapsed) ::after {
  filter: brightness(0) invert(1);
}

.DisplayDayOfMatch {
  display: flex;
  justify-content: center;
  background-color: var(--light-purple-bg);
  border-radius: var(--br-lsm);
  padding: 8px 24px;
  color: var(--light-purple);
  margin: 0px 15px;
}

.subAccordion {
  border: none !important;
  padding: 0px !important;
}

.subAccordion :not(.collapsed) ::after {
  filter: brightness(0);
}

.subAccordion button {
  color: black !important;
  background-color: white !important;
  box-shadow: none !important;
}

/* .customSubAccordion {
  padding: 0px;
} */
.subAccordionHeading {
  font-size: 15px;
}

.listOfSubSchedule {
  background-color: var(--light-purple-bg);
  border-radius: var(--br-medium);
  padding: 12px 16px;
  font-size: var(--font-size-smi);
  margin-bottom: 10px;
  width: fit-content;
}

.newSubMenu {
  height: 1px;
  width: 220;
  border-radius: 5px;
  background-color: var(--light-purple);
  margin-bottom: 12px;
}

.displayOutline {
  display: none;
}

.displayPages {
  display: none;
}

.sidebarLogin {
  border-radius: var(--br-large);
  border: 1px solid white;
  background-color: transparent;
  padding: 7px 19px;
  color: white;
  font-size: 15px;
}

.authenticateBtn {
  display: none !important;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .logo {
    display: none;
  }

  .displayOutline {
    display: block;
  }

  .marginBottom {
    margin-bottom: 9rem !important;
  }

  .languageCard {
    height: fit-content !important;
  }

  .displayPages {
    display: block;
    margin-left: -38px;
    margin-top: 15px;
    font-size: 14px;
    padding: 5px;
    flex-direction: column !important;
  }

  .displayPages nav ul {
    list-style: none;
  }

  .authenticateBtn {
    display: block !important;
    display: flex !important;
    /* justify-content: space-around !important; */
    gap: 16px;
  }

  .loginBtn {
    border-radius: var(--br-large);
    /* border: 1px solid var(--light-purple); */
    border: none;
    background-color: white;
    padding: 6px 40px;
    color: var(--light-purple);
  }

  .registerBtn {
    border-radius: var(--br-large);
    border: 1px solid var(--light-purple);
    border: none;
    background-color: var(--light-purple);
    padding: 6px 40px;
    color: white;
  }

  .sideMenuItem {
    font-weight: 600;
  }

  .timeZoneSelected {
    width: 240px;
    /* opacity: 0; */
    /* position: absolute; */
    /* top: -8px; */
  }
}