.bgSearchForm {
  background-color: var(--white);
  width: 280px;
  height: auto;
  border-radius: var(--br-sm);
  border: 1px solid var(--light-purple);
  padding: 20px;
  /* margin-top: 5px; */
  position: absolute;
  right: 50px;
  top: 80px;
  max-height: 500px;
  overflow-y: scroll;
  z-index: 1;
}

.mobileSearchResult {
  background-color: var(--white);
  width: 280px;
  height: auto;
  border-radius: var(--br-sm);
  border: 1px solid var(--light-purple);
  padding: 20px;
  position: absolute;
  right: 17%;
  top: 12%;
  max-height: 500px;
  color: black;
  overflow-y: scroll;
  z-index: 1;
}

.bgSearchForm::-webkit-scrollbar {
  display: none;
}
